/* src/App.css */
/* Configuración para el modo claro */
@media (prefers-color-scheme: light) {
  body {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
}

/* Configuración para el modo oscuro */
@media (prefers-color-scheme: dark) {
  body {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
}
